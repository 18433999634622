import styled from 'styled-components'
import { AutoColumn } from '../Column'

// import uImage from '../../assets/images/big_unicorn.png'
// import uImage from '../../assets/images/clouds_bigstock-168398480.jpg'
// import uImage from '../../assets/images/papers.co-bd75-anime-sky-cloud-spring-art-illustration-35-3840x2160-4k-wallpaper_resized.jpg'
// import xlUnicorn from '../../assets/images/xl_uni.png'
// import noise from '../../assets/images/noise.png'

export const TextBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  width: fit-content;
  justify-self: flex-end;
`

// export const DataCard = styled(AutoColumn)<{ disabled?: boolean }>`
//   background: radial-gradient(76.02% 75.41% at 1.84% 0%, #ff007a 0%, #2172e5 100%);
//   border-radius: 12px;
//   width: 100%;
//   position: relative;
//   overflow: hidden;
// `

export const DataCard = styled(AutoColumn) <{ disabled?: boolean }>`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #0A1835 0%, #1C54A5 100%);
  border-radius: 12px;
  width: 100%;
  position: relative;
  overflow: hidden;
`

export const CardBGImage = styled.span<{ desaturate?: boolean }>`
  background: url("https://i.imgur.com/EpBaIdo.jpg");
  width: 1000px;
  height: 1000px;
  position: absolute;
  border-radius: 12px;
  opacity: 0.4;
  /* top: -100px; */
  /* left: -100px; */
  /* transform: rotate(-15deg); */
  user-select: none;

  ${({ desaturate }) => desaturate && `filter: saturate(0)`}
`

// export const CardBGImageSmaller = styled.span<{ desaturate?: boolean }>`
//   background: url(${xlUnicorn});
//   width: 1200px;
//   height: 1200px;
//   position: absolute;
//   border-radius: 12px;
//   top: -300px;
//   left: -300px;
//   opacity: 0.4;
//   user-select: none;

//   ${({ desaturate }) => desaturate && `filter: saturate(0)`}
// `

export const CardBGImageSmaller = styled.span<{ desaturate?: boolean }>`
  background: url("https://i.imgur.com/EpBaIdo.jpg");
  width: 1200px;
  height: 1200px;
  position: absolute;
  border-radius: 12px;
  top: -300px;
  left: -300px;
  opacity: 0.4;
  user-select: none;

  ${({ desaturate }) => desaturate && `filter: saturate(0)`}
`

// export const CardNoise = styled.span`
//   background: url(${noise});
//   background-size: cover;
//   mix-blend-mode: overlay;
//   border-radius: 12px;
//   width: 100%;
//   height: 100%;
//   opacity: 0.15;
//   position: absolute;
//   top: 0;
//   left: 0;
//   user-select: none;
// `
export const CardNoise = styled.span``

export const CardSection = styled(AutoColumn) <{ disabled?: boolean }>`
  padding: 1rem;
  z-index: 1;
  opacity: ${({ disabled }) => disabled && '0.4'};
`

export const Break = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  height: 1px;
`
